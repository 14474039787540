import { Injectable } from '@angular/core';
import { RequisicaoService } from './requisicao.service';
import { Subject } from 'rxjs';
import { ls, _carrinhocompras } from 'src/environments/environment';
import { CartItemInterface } from '../interface/cart-item.interface';
import { CartInterface } from '../interface/cart.interface';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public on_add_item:Subject<any> = new Subject<any>();


  constructor(
    public requisicao_service:RequisicaoService    
  ) {
    this.createCartLS();
  }

  all(){
    return this.requisicao_service.get('ecommerce.carrinhocompras.list');
  }

  addItem(_product:any,_quantidade:number = 1){
    return this.requisicao_service.get('ecommerce.carrinhocompras.item.add',{
      _cart_session:ls.get('_cart_session'),
      _product:_product.id,
      _quantity:_quantidade,
      _price:_product.preco
    }).subscribe(
      (_res:any)=>{
        let valor_total = _res.data.qtde * _res.data.valor;
        let cart_item:CartItemInterface = {
          id:_res.data.id,
          descricao:_res.data.descricao,
          quantidade:_res.data.qtde,
          valor:_res.data.valor,
          total:valor_total,
          produto:_res.data.produto_obj,
          src:_res.data.produto_obj.imagemprincipal_src,
          formated_valor:this.getValorFormatado(_res.data.valor),
          formated_valortotal:this.getValorFormatado(valor_total)          
        };
        this.setLSItem(cart_item);
    });
  }

  setLSItem(_item:any){
    if (!this.isExistsCartLS()){
      this.createCartLS();
    }
    let _carrinho = this.current();
    _carrinho.items.push(_item);
    this.setLS(_carrinho);
    this.on_add_item.next(_item);    
  }

  setLS(_carrinho:any = null){
    ls.set('_carrinho',_carrinho == null ? _carrinhocompras : _carrinho);
  }

  current(){
    return ls.get('_carrinho');
  }

  getItens(){
    
    if (!this.isExistsCartLS()){
      console.warn('Carrinho de Compras não encontrado na sessão.');
      return [];
    };
    return this.current().items;
  }

  getSubTotal(){
    let subtotal:number = 0;
    this.getItens().forEach((_element:any)=>{
      subtotal += _element.valor;
    });
    return subtotal;
  }

  getValorFrete(){
    let valor_frete = 0;
    if (this.current().valorfrete == undefined){
      valor_frete = 0;
    }else{
      valor_frete = this.current().valorfrete;
    }
    return valor_frete;
  }

  getTotal(){
    let valor_subtotal  = this.getSubTotal();
    let valor_frete     = this.getValorFrete();

    return valor_subtotal + valor_frete;
  }

  getValorFormatado(valor:number = 0){
    return valor.toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
  }

  isExistsCartLS(){
    return this.current() == null ? false : true;
  }

  createCartLS(){
    if (!this.isExistsCartLS()){      
      this.setLS(_carrinhocompras);
    }
  }

  getQuantidadeItens(){
    let quantidade_itens:number = 0;
    this.getItens().forEach(
      (item:CartItemInterface) => {
        quantidade_itens += item.quantidade;
      }
    );
    return quantidade_itens;
  }

  removeItem(item_id:number){
    return this.requisicao_service.get('ecommerce.carrinhocompras.item.del',{
      _value:item_id
    });    
  }

  removeItemLS(item_id:number){    
    let _cart:CartInterface                   = this.current();
    let _cart_items:Array<CartItemInterface>  = _cart.items;
    let _new_cart_items:Array<CartItemInterface> = [];
    this.clearItemsLS();
    _cart_items.forEach((item:CartItemInterface)=>{
      if (item.id != item_id){
        _new_cart_items.push(item);
      }        
    });
    _cart.items = _new_cart_items;
    this.setLS(_cart);
    return _new_cart_items;
  }

  clearItemsLS(){
    let _cart:CartInterface = this.current();
    _cart.items.splice(0,_cart.items.length);
    this.setLS(_cart);
  }
  create(){
    return this.requisicao_service.get('ecommerce.carrinhocompras.create',{
      _costumer:ls.get('_userid'),
      _session_cart:ls.get('_cart_session')
    });
  }
  load(){
    return this.requisicao_service.get('ecommerce.carrinhocompras.load',{
      _costumer:_carrinhocompras.cliente,
      _session_cart:_carrinhocompras.sessionid
    });
  }
}