<div class="row endereco-lista">
    <app-page-title title="Meu Endereço Principal"></app-page-title>
    <form id="dadospessoais-resumo">
        <div class="form-group">
            <label for="email">Endereço</label>
            <p>{{ address.endereco }}</p>
        </div>
        <div class="form-group">
            <label for="genero">Bairro</label>
            <p>{{ address.bairro }}</p>
        </div>
        <div class="form-group">
            <label for="genero">Cidade</label>
            <p>{{ address.cidade }}</p>
        </div>
        <div class="form-group">
            <label for="email">CEP</label>
            <p>{{ address.cep }}</p>
        </div>
    </form>
</div>