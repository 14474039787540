import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/miles/service/local-storage.service';

@Component({
  selector: 'app-minhaconta',
  templateUrl: './minhaconta.component.html',
  styleUrls: ['./minhaconta.component.css']
})
export class MinhacontaComponent implements OnInit {
  public is_auth:boolean = false;
  constructor(
    public local_storage:LocalStorageService
  ) {
    this.is_auth = this.local_storage.get('is_auth');
  }

  ngOnInit(): void {
  }
  
}