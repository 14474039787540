<div class="row rowfafari" *ngIf="!is_auth">
    <div class="col-md-6">
        <app-page-title title="Quero Me Cadastrar"></app-page-title>
        <small>
            O cadastro é importante pois precisamos de suas informações para melhor atende-lo. Estar em contanto com você e saber suas preferencias fará toda a diferença em nosso atendimento.				
        </small>
        <br><br><br>
        <a routerLink="/queromecadastrar" class="btn btn-cadastra-se">Click aqui para se <b>CADASTRAR</b></a>
    </div>
    <div class="col-md-6 divisoria">
        <app-page-title title="Minha Conta"></app-page-title>
        <small id="texto-login">Olá, para visualizar suas compras, seus dados, alterar ou adicionar endereços é preciso que você entre com seu e-mail e senha. </small>
        <br>
        <form id="form-autenticacao-cliente">
            <input type="hidden" name="ischeckout" id="ischeckout" value="true">
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" name="email" placeholder="Email">
            </div>
            <div class="form-group">
                <label for="senha">Senha</label>
                <input type="password" class="form-control" id="senha" name="senha" placeholder="Senha">
            </div>
            <div class="form-group">
                <a routerLink="/esqueciminhasenha" style="float:right;" id="esquiminhasenha-link">Esqueci Minha Senha</a>
            </div>	
            <button type="button" class="btn" id="btn-logon-entrar" (click)="logar()">Acessar</button>
        </form>
        <br>
        <div id="div-response-logon"></div>
    </div>
</div>
<div class="row" *ngIf="is_auth">
    <app-page-title title="Dados Cadastrais"></app-page-title>
    <form id="dadospessoais-resumo">
        <div class="form-group">
            <label for="email">Nome</label>
            <p>{{ user_data.nome }}</p>
        </div>
        <div class="form-group">
            <label for="genero">Documento</label>
            <p>{{ user_data.documento }}</p>
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <p>{{ user_data.email }}</p>
        </div>
    </form>
</div>