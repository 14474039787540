<nav id="navigation">
    <div class="container">
      <div id="responsive-nav">
        <ul class="main-nav nav navbar-nav">
          <li 
            *ngFor="let m of data"
            [class]="m.active"
          >
            <a 
              data-toggle="tab"
              href="#"
              (click)="goPage(m)"
            >
              {{ m.descricao }}
          </a></li>
        </ul>
      </div>
    </div>
  </nav>