import { CartItemInterface } from "src/app/miles/ecommerce/interface/cart-item.interface";
import { CartInterface } from "src/app/miles/ecommerce/interface/cart.interface";
import { LocalStorageService } from "src/app/miles/ecommerce/service/local-storage.service";

export const environment = {
  production: true
};

export const miles = {
  api:'https://sidercomp.teia.tec.br/miles/webservice/index.php',
  token:'9c372ce9eeaa680bc3c6a0252c711643',
  package:'ecommerce'
}

export var _carrinhocompras:CartInterface = {
  valorfrete:0,
  cliente:0,
  sessionid:'',
  items:Array<CartItemInterface>()
};

// Armazenamento local
export const ls = new LocalStorageService();