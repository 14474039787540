<section class="register page">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <app-page-title title="Faça seu cadastro"></app-page-title>
            </div>            
        </div>
        <div id="registro-cadastro">
            <!-- <?php
                $isnovocadastro = isset($_SESSION["NOVOCADASTRO"])?$_SESSION["NOVOCADASTRO"]:'';
                if ($op == "realizado"){
                    if ($isnovocadastro == 'S'){
                        echo '
                            <div class="row rowfafari">
                                <div class="col-md-12">
                                    <div class="alert alert-success"><b>Obrigado!</b><br/>Seu cadastro foi realizado com Sucesso.</div>
                                    <p id="retornamos-no-email"><a href="'.URL_SITE.'entrar">Clique aqui para efetuar o login.</a></p>
                                </div>
                            </div>
                        ';
                        exit;
                    }else{
                        echo '<META http-equiv="refresh" content="0;URL='.URL_SITE.'entrar">';
                    }
                }
            ?> -->
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="blog-detail-desc">
                    <p>
                        O cadastro é importante pois precisamos de suas informações para melhor atende-lo. Estar em contanto com você e saber suas preferencias fará toda a diferença em nosso atendimento.
                        <br/>
                        <small class="text-info"><b>Não se preocupe!</b> Não usaremos seus dados de forma indevida.</small>
                    </p>
                    <h4>Dicas:</h4>
                    <ul id="dicas-senha-cadastro">
                        <li>Use um e-mail que você acesse com frenquencia. Este será nosso principal meio de comunicação. Fique tranquilo, assim como você não gostamos de <b>SPAM</b>.</li>
                        <li>Não facilite a vida do ladrão colando uma senha fraca.A senha é a principal chave de acesso a sua conta, coloque uma senha forte. Se  você não sabe criar uma senha forte <a href="#" id="link-dicasenhaforte" data-toggle="collapse" data-target="#dicasdesenha" aria-expanded="false" aria-controls="dicasdesenha">Clique Aqui</a>.</li>
                    </ul>

                    <div class="collapse card bg-warning" id="dicasdesenha">
                        <div class="card-header"><strong>Dica de Senha Forte</strong></div>
                        <div class="card-body">
                            <p>Para nevitar que sua conta seja invadida, é importante escolher uma boa senha.</p>
                            <ol class="list-group">
                                <li class="list-group-item">
                                    <b>Escolha senhas fáceis de lembrar</b>
                                    <p>Uma senha fácil de guardar na memória evita que o usuário tenha que anotá-la em papeis ou arquivos, que podem chegar às mãos de pessoas erradas.</p>
                                </li>	

                                <li class="list-group-item">
                                    <b>Não use palavras reais</b>
                                    <p>Uma palavra que pode ser encontrada no dicionário pode facilitar o trabalho de hackers. Para tornar esse trabalho mais difícil, use frases ao invés de substantivos.</p>
                                </li>
                                
                                <li class="list-group-item">
                                    <b>Combine maiúsculas, minúsculas, números e caracteres não alfanuméricos</b>
                                    <p>A inserção desses itens na senha complica ainda mais o trabalho de quem estiver mal intencionado.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>	
            </div>
            <div class="col-md-6">
                <form id="fcadastro">
                    <div class="form-group row p-fluid">
                        <label for="tipo">Tipo</label>
                        <select class="form-control" id="tipo" name="tipo">
                            <option value="1">Física</option>
                            <option value="2">Jurídica</option>
                        </select>
                    </div>                    
                    <div class="form-group row">
                        <label for="nome" [innerText]="label_name"></label>
                        <input type="text" class="form-control" id="nome" name="nome" />
                    </div>
                    <div id="pessoafisicacadastro">
                        <div class="form-group row p-fluid">
                            <label for="cpf">CPF</label>
                            <input type="text" class="form-control" id="cpf" name="cpf" [(ngModel)]="cpf" (blur)="verify('cpf')" />
                            <div id="error-cpf" class="alert alert-danger" role="alert" style="display:none"></div>
                        </div>
                    </div>
                    <div id="pessoajuridicacadastro" style="display:none">
                        <div class="form-group row p-fluid">
                            <label for="cnpj">CNPJ</label>
                            <input type="text" class="form-control" id="cnpj" name="cnpj" [(ngModel)]="cnpj" (blur)="verify('cnpj')" />
                            <div id="error-cnpj" class="alert alert-danger" role="alert" style="display:none"></div>
                        </div>
                        <div class="form-group row p-fluid">
                            <label for="inscricaoestadual">Inscrição Estadual</label>
                            <input type="text" class="form-control" id="inscricaoestadual" name="inscricaoestadual" />
                        </div>
                    </div>									
                    <div class="form-group row p-fluid">
                        <label for="endereco">Endereço</label>
                        <input type="text" class="form-control" id="endereco" name="endereco" />
                    </div>			
                    <div class="form-group row p-fluid">
                        <label for="numero">Número</label>
                        <input type="text" class="form-control" id="numero" name="numero" />
                    </div>
                    <div class="form-group row p-fluid">
                        <label for="complemento">Complemento</label>
                        <input type="text" class="form-control" id="complemento" name="complemento" />
                    </div>
                    <div class="form-group row p-fluid">
                        <label for="bairro">Bairro</label>
                        <input type="text" class="form-control" id="bairro" name="bairro" />
                    </div>	
                    <div class="form-group row p-fluid">
                        <label for="cep">CEP</label>
                        <input type="text" class="form-control" id="cep" name="cep" />
                    </div>
                    <div class="form-group row p-fluid">
                        <label for="cidade">Cidade</label>
                        <input type="text" class="form-control" id="cidade" name="cidade" />
                    </div>	
                    <div class="row p-fluid">		
                        <div class="form-group ">
                            <label for="estado" >Estado</label>
                            <br/>					
                            <select class="form-control" id="estado" name="estado">
                                <option value="" class="selecao-estado" >Estado</option>
                                <option value="1">Acre</option>
                                <option value="2">Alagoas</option>
                                <option value="3">Amapá</option>
                                <option value="4">Amazonas</option>
                                <option value="5">Bahia</option>
                                <option value="6">Ceará</option>
                                <option value="7">Distrito Federal</option>
                                <option value="8">Espírito Santo</option>
                                <option value="9">Goiás</option>
                                <option value="10">Maranhão</option>
                                <option value="11">Mato Grosso</option>
                                <option value="12">Mato Grosso do Sul</option>
                                <option value="13">Minas Gerais</option>
                                <option value="14">Pará</option>
                                <option value="15">Paraíba</option>
                                <option value="16">Paraná</option>
                                <option value="17">Pernambuco</option>
                                <option value="18">Piauí</option>
                                <option value="19">Rio de Janeiro</option>
                                <option value="20">Rio Grande do Norte</option>
                                <option value="21">Rio Grande do Sul</option>
                                <option value="22">Rondônia</option>
                                <option value="23">Roraima</option>
                                <option value="24">Santa Catarina</option>
                                <option value="25">São Paulo</option>
                                <option value="26">Sergipe</option>
                                <option value="27">Tocantins</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row p-fluid">
                        <label for="telefone">Telefone</label>
                        <input type="text" class="form-control" id="telefone" name="telefone" />
                    </div>
                    <div class="form-group row p-fluid">				
                        <label for="email">Email</label>
                        <input type="email" class="form-control" id="email" name="email" [(ngModel)]="email" (blur)="verify('email')" />
                        <div id="error-email" class="alert alert-danger" role="alert" style="display:none"></div>
                    </div>
                    <div class="form-group row p-fluid">
                        <label for="senha">Senha</label>
                        <input type="password" class="form-control" id="senha" name="senha" />
                        <div id="error-senha" class="alert alert-danger" role="alert" style="display:none"></div>
                    </div>
                    <div class="form-group row p-fluid">
                        <label for="csenha">Confirma Senha</label>
                        <input type="password" class="form-control" id="csenha" name="csenha" />
                        <div id="error-csenha" class="alert alert-danger" role="alert" style="display:none"></div>
                    </div>
                    <div class="row p-fluid">
                        <div id="politicaprivacidade-text" class="politicaprivacidade-box"></div>
                        <div class="form-check checkbox-cadastro-politicaprivacidade">
                            <input class="form-check-input row p-fluid" type="checkbox" value="" id="chktermo" name="chktermo" />
                            <label class="form-check-label" for="chktermo">Li e aceito os termos da política de prividade de dados.</label>
                        </div>
                    </div>
                    <div>
                        <div class=" row p-fluid">
                            <button type="button" class="btn btn-success" name="salvar" id="btn-salvar" (click)="salvar()">Cadastrar</button>
                            <!-- <img src="<?=URL_LOADING2?>" id="loading-cadastro" class="loading2" style="display:none;" /> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<p-toast position="top-right" key="cadastro"></p-toast>