<p-table [value]="cart_items">
    <ng-template pTemplate="header">
        <tr>
            <th width="10%">&nbsp;</th>
            <th width="50%">Produto</th>
            <th width="10%" class="text-center">Quantidade</th>
            <th width="15%" class="text-right">Valor</th>
            <th width="15%" class="text-right">Total</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td><img class="resumo-image-produto" [src]="item.src" /></td>
            <td [innerText]="item.descricao"></td>
            <td class="text-center" [innerText]="item.quantidade"></td>
            <td class="text-right" [innerText]="item.formated_valor"></td>
            <td class="text-right" [innerText]="item.formated_valortotal"></td>
        </tr>
    </ng-template>
</p-table>