import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/miles/service/local-storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    public local_storage:LocalStorageService
  ) {
    console.log(this.local_storage.get('is_auth'));
  }

  ngOnInit(): void {
  }

}
