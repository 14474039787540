<section class="pagamento">
    <app-page-title title="Finalizar Pedido"></app-page-title>
    <div 
        id="valortotalcompra-pagamento" 
        class="alert alert-info" 
        role="alert"
    >
        Valor Total da Compra: <b class="valor-total-compra-checkout">{{ display_subtotal }}</b>
    </div>
    <div 
        id="valorsubtotal-pagamento" 
        class="alert" 
        role="alert"
    >
        <small>Subtotal: </small>
        <b class="subtotal-checkout">{{ display_subtotal }}</b>
    </div>
    <div 
        id="valorfrete-pagamento" 
        class="alert" 
        role="alert"
    >
        <small>FRETE: </small>
        <b class="frete-checkout">{{ display_frete }}</b>
    </div>

    <div class="pagamento-lista row">
        <div class="col-md-12">
            <img class="logo-forma-pagamento" src="assets/img/logo-pagseguro.png" />
        </div>
    </div>
    <div class="row" style="display: none;">
        <div id="btns-metodo-pagamento" class="btn-group btn-group-justified" role="group" aria-label="Métodos de Pagamento">
            <div class="col-md-6">
                <a href="#" class="btn btn-info active" id="btn-div-cartao">Cartão de Crédito</a>
            </div>
            <div class="col-md-6">
                <a href="#" class="btn btn-info" id="btn-div-boleto">Boleto</a>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div id="retorno-pagamento-cartao" class="alert alert-success" style="display:none"></div>
        </div>
    </div>
    <div class="pagamento-cartaocredito row">   
        <div class="titularidade-pagamento col-md-12" style="display: none;">
            <div class="radio-inline">
                <input type="radio" name="optionsRadios" id="soutitularcartao1" value="S" checked>
                <label>Sou titular</label>
            </div>
            <div class="radio-inline">
                <input type="radio" name="optionsRadios" id="soutitularcartao2" value="N">
                <label>Não Sou titular</label>
            </div>
        </div>
        <div class="form-group col-md-6">
            <label for="bandeira">Bandeira</label>
            <select class="form-control" id="bandeira" name="bandeira" required>
            </select>
        </div>
        <div class="form-group col-md-6">
            <label for="nome">Titular</label>
            <input 
                type="text" 
                class="form-control" 
                id="nome"
                name="nome"
                placeholder="Nome do Titular do Cartão" 
                #required
                [(ngModel)]="nome"
            >
        </div>			
        <div class="form-group col-md-6">
            <label for="numerocartao">Número</label>
            <input 
                type="text"
                class="form-control"
                id="numerocartao"
                name="numerocartao"
                placeholder="Número do Cartão"
                #required
                [(ngModel)]="numero_cartao"
                (blur)="loadParcelamento()"
            >
            <input type="hidden" class="form-control" id="bandeiracartao" name="bandeiracartao">
            <input type="hidden" class="form-control" id="identificador" name="identificador">
            <div id="msg-erro-numerocartao"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
        <div class="form-group col-md-6" style="display:none;">
            <label for="senha">Senha</label>
            <input type="password" class="form-control" id="senha" name="senha" placeholder="Senha">
        </div>
        <div class="form-group col-md-6">
            <label for="validade">Validade</label>
            <input 
                type="text" 
                class="form-control" 
                id="validade" 
                name="validade" 
                placeholder="Validade" 
                readonly
                #required
                (blur)="checkValidade()"
            >
            <div id="msg-erro-validade"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
        <div class="form-group col-md-6">
            <label for="codigoseguranca">Código de Segurança</label>
            <input 
                type="text" 
                class="form-control" 
                id="codigoseguranca" 
                name="codigoseguranca" 
                placeholder="Código de Segurança" 
                readonly
                #required
                [(ngModel)]="cvc"
            >
            <div id="msg-erro-codigoseguranca"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
        <div class="form-group col-md-6">
            <label for="cpf-cartao">CPF</label>
            <input 
                type="text" 
                class="form-control" 
                id="cpf-cartao" 
                name="cpf-cartao" 
                placeholder="CPF do Titular" 
                required
                #required
                [(ngModel)]="cpf"
                (blur)="checkCPF()"
            >
            <div id="msg-erro-cpf-cartao"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
        <div class="form-group col-md-6">
            <label for="datanascimento-cartao">Data Nascimento</label>
            <input 
                type="text"
                class="form-control"
                id="datanascimento-cartao"
                name="datanascimento-cartao"
                placeholder="Data de Nascimento"
                #required
                [(ngModel)]="data_nascimento"
                (blur)="checkDataNascimento()"
            >
            <div id="msg-erro-datanascimento-cartao"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
        <div class="form-group col-md-6">
            <label for="telefone">Telefone</label>
            <input 
                type="text" 
                class="form-control" 
                id="telefone-cartao" 
                name="telefone-cartao" 
                placeholder="Telefone do Titular" 
                #required
                [(ngModel)]="telefone"
                (blur)="checkTelefone()"
            >
            <div id="msg-erro-telefone-cartao"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
        <div class="form-group col-md-6">
            <label for="parcelamento">Parcelamento</label>
            <select class="form-control" id="parcelamento" name="parcelamento" readonly required>
            </select>
            <img src="assets/img/loading2.gif" id="loading-parcelamento" style="display:none;" />
            <div id="msg-erro-parcelamento"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
        <div class="col-md-12">
            <input type="hidden" class="form-control" id="tokencartao" name="tokencartao">
            <div id="politicaprivacidade"></div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="chktermo" name="chktermo">
                <label class="form-check-label" for="chktermo">Li e aceito os termos da política de prividade de dados.</label>
            </div>
            <div id="msg-erro-politicaprivacidade"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
        <div class="col-md-12">  
            <button 
                type="button" 
                class="btn btn-success" 
                id="finalizar-cartaocredito"
                (click)="finalizarCartao()"
            >Pagar e Finalizar Pedido</button>
            <img src="assets/img/loading2.gif" class="loading-finalizar" style="display:none" />		
            <div id="msg-erro-pedido"  class="alert alert-danger msg-error-formgroup"></div>
        </div>
    </div>
    <div id="div-pagamento-boleto" style="display:none;" class="row">
        <div class="col-md-12">
            <div id="retorno-pagamento-boleto"></div>
        </div>
        <form>
            <div class="form-group col-md-6">
                <label for="cpftiular">CPF</label>
                <input type="text" class="form-control" id="cpftiular" name="cpftiular" placeholder="CPF do Titular" required>
            </div>
            <div class="form-group col-md-6">
                <label for="telefone">Telefone</label>
                <input type="text" class="form-control" id="telefone" name="telefone" placeholder="Telefone do Titular" required>
            </div>
            <button type="button" class="btn btn-success" id="finalizar-boleto">Gerar Boleto e Finalizar Pedido</button>
            <img src="assets/img/loading2.gif" class="loading-finalizar" style="display:none" />
        </form>
    </div>
</section>