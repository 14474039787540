  <!-- MAIN HEADER -->
  <div id="header">
    <!-- container -->
    <div class="container">
      <!-- row -->
      <div class="row">
        <!-- LOGO -->
        <div class="col-md-3">
          <div class="header-logo">
            <a href="#" class="logo">
              <img src="./assets/img/logo.png" alt="">
            </a>
          </div>
        </div>
        <!-- /LOGO -->

        <!-- SEARCH BAR -->
        <div class="col-md-6">
        </div>
        <!-- /SEARCH BAR -->

        <!-- FAVORITOS E CARRINHO DE COMPRAS -->
        <app-cart-header></app-cart-header>
        <!-- /FAVORITOS E CARRINHO DE COMPRAS -->

      </div>
      <!-- row -->
    </div>
    <!-- container -->
  </div>
  <!-- /MAIN HEADER -->