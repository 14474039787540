import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../service/cart.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  public steps: any;
  public subtotal:string  = 'R$ 0,00';
  public frete:string     = 'R$ 0,00';
  public total:string     = 'R$ 0,00';
  public current_step     = 0;
  constructor(
    public cart_service:CartService,
    public router:Router
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init(){
    this.steps = [
      {label: 'Resumo', path:'resumo'},
      {label: 'Autenticação', path:'autenticacao'},
      {label: 'Endereço', path:'endereco'},
      {label: 'Entrega', path:'entrega'},
      {label: 'Pagamento', path:'pagamento'}
    ];
    this.refresh();
  }
  
  refresh(){
    this.setTotais();
  }
  setTotais(){
    this.subtotal = this.cart_service.getValorFormatado(this.cart_service.getSubTotal());
    this.frete    = this.cart_service.getValorFormatado(this.cart_service.getValorFrete());
    this.total    = this.cart_service.getValorFormatado(this.cart_service.getTotal());
  }

  nextStep(){    
    if (this.current_step < (this.steps.length -1)){
      this.goStep(++this.current_step);
    }
  }
  previousStep(){
    if (this.current_step > 0){
      this.goStep(--this.current_step);
    }
  }
  goStep(_index:number){
    this.router.navigateByUrl('/checkout/' + this.steps[ _index ].path);
  }

}