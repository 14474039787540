<h4>Meus Pedidos</h4>
<hr/>
<table class="table" id="legenda-pedidos">
	<tbody>
		<tr>
			<td class="bg-info" width="25%">Aberto</td>
			<td class="bg-warning" width="25%">Análise</td>
			<td class="bg-danger" width="25%">Cancelado</td>
			<td class="bg-success" width="25%">Finalizado</td>
		</tr>
	</tbody>	
</table>
<table class="table table-hover" id="lista-pedidos">
	<thead>
		<tr>
			<th width="15%" class="text-left">Nº Pedido</th>
			<th width="60%" class="text-left">Forma de Pagamento</th>
			<th width="10%" class="text-center">Qtde</th>
			<th width="15%" class="text-right">Valor Total</th>
		</tr>
	</thead>
	<tbody>
	</tbody>
</table>        