import { Component, OnInit } from '@angular/core';
import { ls } from 'src/environments/environment';
import { DashboardService } from '../../../service/dashboard.service';

export interface AddressInterface {
  endereco:string,
  bairro:string,
  cidade:string,
  cep:string
}
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddresComponent implements OnInit {

  public address:AddressInterface = {
    endereco:'',
    bairro:'',
    cidade:'',
    cep:''
  };
  constructor(
    public dashboard_service:DashboardService
  ) {

  }

  ngOnInit(): void {
    this.setAddress();
  }

  setAddress(){
    if (ls.get('user') == null){
      this.loadAddressService();
    }else{
      this.loadAddressLS();
    }
  }

  loadAddressLS(){
    let address_ls = ls.get('user').user_address;
    this.address = {
      endereco:address_ls.logradouro,
      bairro:address_ls.bairrodesc,
      cidade:address_ls.cidadedesc,
      cep:address_ls.cep
    }
  }
  loadAddressService(){
    this.dashboard_service.carregarEnderecoCliente()
    .subscribe((_res:any) => {
      this.address = {
        endereco:_res.data.logradouro,
        bairro:_res.data.bairrodesc,
        cidade:_res.data.cidadedesc,
        cep:_res.data.cep
      }       
    });
  }
}