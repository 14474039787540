import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { FormService } from '../../../service/form.service';
import { RequisicaoService } from '../../../service/requisicao.service';

declare var $:any;
export interface UserData{
  nome:string,
  email:string,
  documento:string
}
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  public required_fields  = new Array("email","senha");
  public is_auth:boolean  = false;
  public user_data:UserData;

  constructor(
    public requisicao_service:RequisicaoService,
    public form_service:FormService,
    public auth_service:AuthService
  ) {
    this.user_data = {
      nome:'',
      email:'',
      documento:''      
    };
    this.auth_service.on_is_auth
    .subscribe((_is_auth:boolean) => {
      this.is_auth = _is_auth;
    });
  }

  ngOnInit(): void {
    this.is_auth = this.auth_service.isAuth();
  }

  
  logar()
  {
    $("#div-response-logon").hide();

    if (!this.validar()) {
        return;
    }
    
    this.auth_service
    .logon($("#email").val(),$("#senha").val())
    .subscribe(
      (response:any) => {
        if (response.status == 'success'){
          this.auth_service.setAuth(response.data);
          this.setUserData(response.data.user);
        }else{          
          $("#div-response-logon").addClass("alert alert-danger");
          $("#div-response-logon").attr('role','alert');
          $("#div-response-logon").html(response.msg);
          $("#div-response-logon").show();
        }
      }
    );
  }

	validar():boolean {
    let is_valid  = true;
		this.required_fields.forEach((campo:string) => {
			if ($("#" + campo).val() == ""){
        this.form_service.setError(campo);
			 	setTimeout(function(){
			 		$("#" + campo).focus();
			 	},100);
        is_valid = false;
        return;
			}else{
        this.form_service.setDefault(campo);
      }
    });

    if (!is_valid) return false;
		return true;
	}

  setUserData(user_data:any){
    this.user_data ={
      nome: 'Edilson',
      email:user_data.email,
      documento:(user_data.cpf + user_data.cnpj)
    }
  }
}