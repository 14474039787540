import { Injectable } from '@angular/core';
import { ls } from 'src/environments/environment';
import { RequisicaoService } from './requisicao.service';
import { Subject } from 'rxjs';

declare var $:any;
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public on_is_auth:Subject<boolean> = new Subject<boolean>();
  constructor(
    public requisicao_service:RequisicaoService
  ) { }

  logon(_email:string,_senha:string){
    return this.requisicao_service.get('ecommerce.logon.auth',{
      email: _email,
      senha: _senha
    });
  }

  setAuth(user_data:any,){
    ls.set('user',user_data);
    ls.set('_is_auth',true);
    this.on_is_auth.next(true);
    this.setAccessToken(user_data.token);
  }

  setAccessToken(_token:string){
    ls.set('_token_access',_token);
  }

  getAccessToken(){
    return ls.get('_token_access');
  }

  isAuth():boolean{
    return ls.get('_is_auth');
  }
}