<!--IMPORTANDO SLIDER-->
<app-slider></app-slider>
<!--IMPORTANDO SLIDER-->

  <!-- SECTION -->
  <div class="section">
    <!-- container -->
    <div class="container">
      <!-- row -->
      <div class="row">
  
        <!-- section title -->
        <div class="col-md-12">
          <app-page-title title="Novos Produtos"></app-page-title>
        </div>
        <!-- /section title -->
        
        <!-- Products tab & slick -->
        <div class="col-md-12" >
          <div class="row" >
              <app-product-item-list 
                #product_list
                [products]="data"
              ></app-product-item-list>
          </div>
        </div>
        <!-- Products tab & slick -->
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </div>
  <!-- /SECTION -->
  
  <!-- NEWSLETTER -->
  <div id="newsletter" class="section">
    <!-- container -->
    <div class="container">
      <!-- row -->
      <div class="row">
        <div class="col-md-12">
          <div class="newsletter">
            <p>Deixe seu email para receber <strong>Novas Ofertas</strong></p>
            <form>
              <input class="input" type="email" placeholder="Digite seu email aqui">
              <button class="newsletter-btn"><i class="fa fa-envelope"></i> Inscrever-se</button>
            </form>
            <ul class="newsletter-follow">
              <li>
                <a href="#"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-instagram"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-pinterest"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /row -->
    </div>
    <!-- /container -->
  </div>
  <!-- /NEWSLETTER -->