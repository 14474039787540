<div id="geral" class="geral">
    <section class="sec1">
        <div>
          <h3>CATEGORIA</h3>

        </div>
        <div class="preco">
          <h3>PREÇO</h3>
          <input type="number" class="rangeDisplay" name="range" id="range" value="{{$value}}" placeholder="Min">
          <input type="number" class="rangeDisplay" name="range" id="range" value="{{$value}}" placeholder="Max">
          <input type="range" class="range" min="400" max="10000">
        </div>
        <div class="marca">
          <h3>MARCA</h3>
          <div class="itens">
            <div class="item">
              <input type="checkbox" name="" id="">
              <label for="NHS">NHS</label>
            </div>
            <div class="item">
              <input type="checkbox" name="" id="">
              <label for="NHS">SMS</label>
            </div>
            <div class="item">
              <input type="checkbox" name="" id="">
              <label for="NHS">APC</label>
            </div>
          </div>
        </div>
    </section>
    <section class="sec2">
      <!-- Products tab & slick -->
      <div class="col-md-12" >
        <div class="row" >
          <div class="products-tabs">
            <!-- tab -->
            <div id="tab1" class="tab-pane active">
              <div class="products-slick" data-nav="#slick-nav-1" >


                <!-- product -->
                <div class="product" *ngFor="let produto of lojaProdutos">
                  <div class="product-img">
                    <img [src]="produto.imagemprincipal_src" alt="">
                    <div class="product-label">
                      <span class="sale">-30%</span>
                      <span class="new">Novidade</span>
                    </div>
                  </div>
                  <div class="product-body">
                    <p class="product-category">{{produto.categoria_desc}}</p>
                    <h3 class="product-name"><a href="#">{{produto.nome}}</a></h3>
                    <h4 class="product-price">{{produto.formated_preco}} <del class="product-old-price">R$990.00</del></h4>
                    <div class="product-rating">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </div>
                    <div class="product-btns">
                      <button class="add-to-wishlist"><i class="fa fa-heart-o"></i><span class="tooltipp">adicionar a lista de desejos</span></button>
                      <button class="quick-view" (click)="irDetalheProduto(produto.id)"><i class="fa fa-eye"></i><span class="tooltipp">Visualizar</span></button>
                    </div>
                  </div>
                  <div class="add-to-cart">
                    <button class="add-to-cart-btn"><i class="fa fa-shopping-cart"></i> Adicionar ao carrinho</button>
                  </div>
                </div>
                <!-- /product -->


                
              </div>
              <div id="slick-nav-1" class="products-slick-nav"></div>
            </div>
            <!-- /tab -->
          </div>
        </div>
      </div>
      <!-- Products tab & slick -->
        
    </section>
</div>