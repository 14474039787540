
    <div class="delivery-media">
        <img src="assets/img/frente-loja1.jpg" class="mr-3 img-opcoes-lojas" alt="Matriz">
        <div class="media">    
            <div class="media-body">
                <h3>Retirar na Loja</h3>
                <div class="form-check form-check-inline">
                    <p-radioButton name="groupname" value="val1" [(ngModel)]="selectedValue" label=""></p-radioButton>
                    <label class="form-check-label" for="lojaOptionMatriz">Matriz</label>
                </div>
                <hr>
                <form>
                    <div class="form-group">
                        <p class="form-text text-muted">Endereço:</p>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item list-group-item-light">Rua Cônego Aníbal Maria Difrância, 195</li>
                            <li class="list-group-item list-group-item-light">Pinheirinho, Criciúma/SC.</li>
                            <li class="list-group-item list-group-item-light"><strong>Aberto das 8h as 12h e 13:30 as 18h</strong></li>
                        </ul>
                    </div>
                </form>
            </div>
        </div>    
    </div>