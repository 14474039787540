<section class="minhaconta page">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <app-page-title title="Minha Conta"></app-page-title>
                <p>Seja bem vindo ao painel de controle da sua conta. Abaixo você poderá configuar seus dados e pedidos de compra.<br/>
                <small class="text-info"><b>LEMBRE-SE!</b> Manter seus dados atualizados torna a finalização de seu pedido muito mais rápido e evita erros na entrega.</small></p>            
            </div>            
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="list-group">
                    <a routerLink="dados" class="list-group-item" data-url="dadospessoais">
                        <i class="fa fa-user icone-op-minhaconta" aria-hidden="true"></i>
                        <label>Meus Dados</label>
                    </a>
                    <a routerLink="endereco" class="list-group-item disabled" data-url="meusenderecos">
                        <i class="fas fa-location-dot icone-op-minhaconta" aria-hidden="true"></i>
                        <label>Meu Endereço</label>
                    </a>
                    <a routerLink="pedido" class="list-group-item" data-url="meuspedidos">
                        <i class="fas fa-gift icone-op-minhaconta" aria-hidden="true"></i>
                        <label>Meus Pedidos</label>
                    </a>
                    <a routerLink="alterarsenha" class="list-group-item" data-url="alterarsenha">
                        <i class="fas fa-key icone-op-minhaconta" aria-hidden="true"></i>
                        <label>Alterar Senha</label>
                    </a>
                </div>		
            </div>
            <div class="col-md-8">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</section>