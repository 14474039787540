<div id="top-header">
  <div class="container">
    <ul class="header-links pull-left">
      <li><i class="fa fa-phone"></i> +55 48 34377133</li>
      <li><i class="fa fa-envelope-o"></i> sidercomp@sidercomp.com.br</li>
      <li><i class="fa fa-map-marker"></i>Rua Cônego Aníbal Maria Difrância, 195</li>
    </ul>
    <ul class="header-links pull-right">
      <li><a routerLink="/cadastro"><i class="fa fa-user-plus"></i> Cadastrar-se</a></li>
      <li><a routerLink="/minhaconta"><i class="fa fa-user"></i> Minha Conta </a></li>
    </ul>
  </div>
</div>