import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})
export class DashboardPedidoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
