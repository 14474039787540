<!-- FOOTER -->
<footer id="footer">
    <!-- top footer -->
    <div class="section">
      <!-- container -->
      <div class="container">
        <!-- row -->
        <div class="row">
          <div class="col-md-3 col-xs-6">
            <div class="footer">
              <h3 class="footer-title">A empresa</h3>
              <p>Nascida em Criciúma/SC em 1996, a Sidercomp Informática se destaca a cada dia pelos serviços prestados com agilidade e eficiência nos mais diversos setores corporativos e governo de Criciúma e região.</p>
              <ul class="footer-links">
                <li><a href="#"><i class="fa fa-map-marker"></i>Rua Cônego Aníbal Maria Difrância, 195</a></li>
                <li><a href="#"><i class="fa fa-phone"></i>+55 (48) 3437-7133</a></li>
                <li><a href="#"><i class="fa fa-envelope-o"></i>sidercomp@sidercomp.com.br</a></li>
              </ul>
            </div>
          </div>
  
          <div class="col-md-3 col-xs-6">
            <div class="footer">
              <h3 class="footer-title">Categorias</h3>
              <ul class="footer-links">
                <li><a href="#">Ofertas da semana</a></li>
                <li><a href="#">Notebooks</a></li>
                <li><a href="#">Smartphones</a></li>
                <li><a href="#">Cameras</a></li>
                <li><a href="#">Accessorios</a></li>
              </ul>
            </div>
          </div>
  
          <div class="clearfix visible-xs"></div>
  
          <div class="col-md-3 col-xs-6">
            <div class="footer">
              <h3 class="footer-title">Informações</h3>
              <ul class="footer-links">
                <li><a href="#">Sobre nós</a></li>
                <li><a href="#">Contato</a></li>
                <li><a href="#">Politicas de privacidade</a></li>
                <li><a href="#">Encomendas e Devoluções</a></li>
                <li><a href="#">Termos & Condições</a></li>
              </ul>
            </div>
          </div>
  
          <div class="col-md-3 col-xs-6">
            <div class="footer">
              <h3 class="footer-title">Serviços</h3>
              <ul class="footer-links">
                <li><a href="#">Minha Conta</a></li>
                <li><a href="#">Ver Carrinho</a></li>
                <li><a href="#">Lista de Desejos</a></li>
                <li><a href="#">Rastrear meu pedido</a></li>
                <li><a href="#">Ajuda!</a></li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->
    </div>
    <!-- /top footer -->
  
    <!-- bottom footer -->
    <div id="bottom-footer" class="section">
      <div class="container">
        <!-- row -->
        <div class="row">
          <div class="col-md-12 text-center">
            <ul class="footer-payments">
              <li><a href="#"><i class="fa fa-cc-visa"></i></a></li>
              <li><a href="#"><i class="fa fa-credit-card"></i></a></li>
              <li><a href="#"><i class="fa fa-cc-paypal"></i></a></li>
              <li><a href="#"><i class="fa fa-cc-mastercard"></i></a></li>
              <li><a href="#"><i class="fa fa-cc-discover"></i></a></li>
              <li><a href="#"><i class="fa fa-cc-amex"></i></a></li>
            </ul>
            <span class="copyright">
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
              Copyright &copy;<script>document.write(new Date().getFullYear());</script> 2022 Todos os direitos reservados | Sidercomp Informatica LTDA<p>
                Desenvolvido por 
                <a class="link-dev" href="https://teia.tec.br" target="_blank">
                <img src="./assets/img/favicon-32px.png" class="icon-teia-rodape">
                Teia - Tecnologia WEB</a>.</p>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            </span>
          </div>
        </div>
          <!-- /row -->
      </div>
      <!-- /container -->
    </div>
    <!-- /bottom footer -->
  </footer>
  <!-- /FOOTER -->