import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService } from 'src/app/miles/ecommerce/service/menu.service';
import { ProductService } from '../../service/product.service';
import { Router } from '@angular/router';
import { ProductInterface } from '../../interface/product.interface';

declare var $:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	public data:Array<ProductInterface> 		= [];	
	@ViewChild('product_list') _product_list:any;
  	constructor(
		public menu:MenuService,
		public product_service:ProductService,
		public router:Router
  	) { }

  ngOnInit(): void {
	this.loadProducts();
	this.jQuery();
  }

  loadProducts(){
    this.product_service.home().subscribe(
      (res:any) => {
		this.data = res.data;
		//this._product_list.update(res.data);
		this._product_list.setSlik();
      }
    );
  }

  jQuery(){
	// Mobile Nav toggle
	$('.menu-toggle > a').on('click', function (e:any) {
		e.preventDefault();
		$('#responsive-nav').toggleClass('active');
	})

	// Fix cart dropdown from closing
	$('.cart-dropdown').on('click', function (e:any) {
		e.stopPropagation();
	});

	/////////////////////////////////////////
	


	// Products Widget Slick
	// $('.products-widget-slick').each(function(index:number,element:any) {
	// 	var $this = $(element),
	// 			$nav = $this.attr('data-nav');

	// 	$this.slick({
	// 		infinite: true,
	// 		autoplay: true,
	// 		speed: 300,
	// 		dots: false,
	// 		arrows: true,
	// 		appendArrows: $nav ? $nav : false,
	// 	});
	// });

	/////////////////////////////////////////

// 	// Product Main img Slick
// 	$('#product-main-img').slick({
//     infinite: true,
//     speed: 300,
//     dots: false,
//     arrows: true,
//     fade: true,
//     asNavFor: '#product-imgs',
//   });

// 	// Product imgs Slick
//   $('#product-imgs').slick({
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     arrows: true,
//     centerMode: true,
//     focusOnSelect: true,
// 		centerPadding: 0,
// 		vertical: true,
//     asNavFor: '#product-main-img',
// 		responsive: [{
//         breakpoint: 991,
//         settings: {
// 					vertical: false,
// 					arrows: false,
// 					dots: true,
//         }
//       },
//     ]
//   });

	// Product img zoom
	// var zoomMainProduct = document.getElementById('product-main-img');
	// if (zoomMainProduct) {
	// 	$('#product-main-img .product-preview').zoom();
	// }

	/////////////////////////////////////////

	// Input number
	// $('.input-number').each(function(index:number,element:any) {
	// 	var $this = $(element),
	// 	$input = $this.find('input[type="number"]'),
	// 	up = $this.find('.qty-up'),
	// 	down = $this.find('.qty-down');

	// 	down.on('click', function () {
	// 		var value = parseInt($input.val()) - 1;
	// 		value = value < 1 ? 1 : value;
	// 		$input.val(value);
	// 		$input.change();
	// 		updatePriceSlider($this , value)
	// 	})

	// 	up.on('click', function () {
	// 		var value = parseInt($input.val()) + 1;
	// 		$input.val(value);
	// 		$input.change();
	// 		updatePriceSlider($this , value)
	// 	})
	// });

	// var priceInputMax = document.getElementById('price-max'),
	// 		priceInputMin = document.getElementById('price-min');

	// priceInputMax.addEventListener('change', function(){
	// 	updatePriceSlider($(this).parent() , this.value)
	// });

	// priceInputMin.addEventListener('change', function(){
	// 	updatePriceSlider($(this).parent() , this.value)
	// });

	// function updatePriceSlider(elem , value) {
	// 	if ( elem.hasClass('price-min') ) {
	// 		console.log('min')
	// 		priceSlider.noUiSlider.set([value, null]);
	// 	} else if ( elem.hasClass('price-max')) {
	// 		console.log('max')
	// 		priceSlider.noUiSlider.set([null, value]);
	// 	}
	// }

	// Price Slider
	//var priceSlider = document.getElementById('price-slider');
	// var priceSlider = $('#price-slider');
	// debugger;
	// console.log(priceSlider);
	// if (priceSlider) {
	// 	noUiSlider.create(priceSlider, {
	// 		start: [1, 999],
	// 		connect: true,
	// 		step: 1,
	// 		range: {
	// 			'min': 1,
	// 			'max': 999
	// 		}
	// 	});

	// 	priceSlider.noUiSlider.on('update', function( values, handle ) {
	// 		var value = values[handle];
	// 		handle ? priceInputMax.value = value : priceInputMin.value = value
	// 	});
	// }

  }
}