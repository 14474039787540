import { Component, OnInit } from '@angular/core';
import { ls, _carrinhocompras } from 'src/environments/environment';
import { CartService } from './miles/ecommerce/service/cart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  constructor(
    public cart_service:CartService
  ){

  }
  ngOnInit(){
    if (ls.get('_cart_session') == '' || ls.get('_cart_session') == null)
      this.cart_service.create()
      .subscribe(
        (response:any) => {
          _carrinhocompras.sessionid = response.data.sessionid;
          ls.set('_cart_session',response.data.sessionid);
          this.cart_service.setLS(_carrinhocompras);
        }
      );
    else this.cart_service.load();
  }
}