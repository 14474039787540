<h4>Alterar Senha</h4>
<hr/>
<form id="form-alterarsenha">
	<div class="form-group">
		<label for="senha">Senha</label>
		<input type="password" class="form-control" id="senha" placeholder="Digite a Senha">
	</div>
	<div class="form-group">
		<label for="csenha">Confirma Senha</label>
		<input type="password" class="form-control" id="csenha" placeholder="Confirme a Senha">
	</div>
	<button type="button" class="btn btn-success" name="salvar" id="btn-alterarsenha">Alterar</button>
    <img src="<?=URL_LOADING2?>" id="loading-alterarsenha" class="loading2" style="display:none" />
	<div id="retorno-alterarsenha"></div>
</form>
